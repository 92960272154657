<template>
  <Box
    class="result-card--final"
    padding="24px"
  >
    <div
      class="result-card__badge"
      :style="{ background: color }"
    >
      <img
        class="result-card__badge-icon"
        :src="image"
      >
    </div>

    <h3>{{ title }}</h3>
  </Box>
</template>

<script>
export default {
  name: 'AssessmentResultsExamFinal',

  props: {
    color: {
      type: String,
      required: true,
    },

    image: {
      type: String,
      required: true,
    },

    title: {
      type: String,
      required: true,
    },
  },
}
</script>

<style lang="sass">

.result-card--final

  .result-card__badge
    display: flex
    align-items: center
    justify-content: center
    width: 72px
    height: 72px
    border-radius: 8px
    color: white
    margin-bottom: 40px

    &-icon
      height: 40px
      width: 40px

  &:hover
    cursor: pointer
    box-shadow: 0 6px 12px rgba(0, 0, 0, 0.35)

</style>
